import axios from 'axios';

const NFTID = "0xe8c98ed443c8fe3590c38cd52167a6aa2be76aaf694d16d89dba11a3e3bbf16c"
const TOKENADDR = "0x8080bbc4aad9755763d691cf23599de5d8e97742"

export function doAccountQuery(addr) {
    axios({
      method: 'get',
      url: `https://api3.loopring.io/api/v3/account?owner=`+addr,
    }).then(function (response) {
      //console.log(response.data);
      //console.log(response.data["publicKey"]);
      return response.data;
    });
  }
  
export async function doApiQuery(acct_id, eddsaSig) {
    return axios({
      method: 'get',
      url: `https://api3.loopring.io/api/v3/apiKey?accountId=`+acct_id,
      headers: {
        'X-API-SIG' : eddsaSig
      }
    })
  }
  
export async function getNftBalance(_web3, lrcAcct, tokenAddr, nftId){
    const nftBalance = await LoopringAPI.nftAPI.getNFTBalance({
      _web3,
      account: lrcAcct,
      tokenAddress: tokenAddr,
      nftId: nftId,
      nftType: sdk.NFTType.ERC1155,
    });
    //console.log(nftBalance)
    return nftBalance
  }