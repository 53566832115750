import axios from 'axios';
import * as sdk from '@loopring-web/loopring-sdk'
import * as ethers from 'ethers'
import Web3 from "web3";

import * as wallets from './wallet.js'
import * as draw from './draw.js'
import * as Tone from 'tone'
import audioUrlTriangle from '@/assets/alphabet_soup.flac'
import audioUrlCube from '@/assets/spinning_cube.3.flac'
import audioUrlTorus from '@/assets/torus.flac'




export class LoopringAPI {
  static userAPI;
  static exchangeAPI;
  static walletAPI;
  static nftAPI;
  static InitApi = (chainId) => {
    LoopringAPI.userAPI = new sdk.UserAPI({ chainId });
    LoopringAPI.exchangeAPI = new sdk.ExchangeAPI({ chainId });
    LoopringAPI.walletAPI = new sdk.WalletAPI({ chainId });
    LoopringAPI.nftAPI = new sdk.NFTAPI({ chainId });
  };
}

const DEBUG = false;
const REGL_ENGINE = true; // REGL or WGL rendering options
const waveformSize = 2048*4; // must be a power of 2

LoopringAPI.InitApi(sdk.ChainId.MAINNET);
var player = new Tone.Player();
var stopRender = false;
var stopPlayer = false;
let { hostname, port } = window.location;

// shared access token for all APIs
let accessToken = 1;
let tokenClaims = {};
let ethereumAddr = [];
let lrcAddress = undefined
let web3Lrc = undefined
let eddsaKey = undefined
let apiKey = undefined

// change parameters for development since it uses CORS
if (process.env.NODE_ENV !== 'production') {
  port = 9090;
  axios.defaults.withCredentials = true;
}

axios.defaults.baseURL = `https://${hostname}:${port}`;


export default {
  checkMetaMask() {
    //Basic Actions Section
    //const onboardButton = document.getElementById('connectButton');
    //Created check function to see if the MetaMask extension is installed
    const isMetaMaskInstalled = () => {
      //Have to check the ethereum binding on the window object to see if it's installed
      const { ethereum } = window;
      return Boolean(ethereum && ethereum.isMetaMask);
    };
    const MetaMaskClientCheck = () => {
      //Now we check to see if MetaMask is installed
      if (!isMetaMaskInstalled()) {
        //If it isn't installed we ask the user to click to install it
        return 'install';
      } else {
        //If it is installed we change our button text
        return 'connect';
      }
    };
    if(DEBUG)
      console.log(MetaMaskClientCheck());

    return MetaMaskClientCheck();
  },
  async checkWeb3LoggedIn(){
    // Check if using chrome.
    // TODO how do mobile wallets fit in with this? Probably poorly..
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if(!isChrome) return false;

    const { ethereum } = window
    const provider = new ethers.providers.Web3Provider(ethereum);
    const accounts = await provider.listAccounts();
    if(DEBUG)
      console.log(accounts)
    if(accounts.length > 0)
      return true
    return false
  },
  
  async connectMetaMask() {
    try {
      const { ethereum } = window
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' }).then(
        (value) => value
      )
      ethereumAddr = accounts;
      //wallets.doAccountQuery(ethereumAddr[0])
      const {accInfo} = await LoopringAPI.exchangeAPI.getAccount({
        owner: ethereumAddr[0],
      });
      if(DEBUG)
        console.log(accInfo);
      
      return ethereumAddr;
    } catch (error) {
      console.error(error);
    }
  },
  async createLrc(act, prov, chain){
    if(DEBUG)
      console.log(chain)
    const web3 = new Web3(prov);
    //await ethereum.enable(); // TODO deprecated
    const {accInfo} = await LoopringAPI.exchangeAPI.getAccount({
      owner: act,
    });
    if(DEBUG)
      console.log("accInfo:", accInfo);

    const { exchangeInfo } = await LoopringAPI.exchangeAPI.getExchangeInfo();
    const keySeed = sdk.BaseAPI.KEY_MESSAGE.replace(
      "${exchangeAddress}",
      exchangeInfo.exchangeAddress
    ).replace("${nonce}", (accInfo.nonce - 1).toString());

    eddsaKey = await sdk.generateKeyPair({
      web3: web3,
      address: accInfo.owner,
      keySeed: keySeed,
      walletType: sdk.ConnectorNames.MetaMask,
      chainId: sdk.ChainId.MAINNET,
    });
    if(DEBUG)
      console.log("eddsakey:", eddsaKey.sk);
    const params = new Map([
      ["accountId", accInfo.accountId],
    ]);

    const eddsaSig = sdk.getEdDSASig(
      "GET",
      "https://api3.loopring.io/",
      "api/v3/apiKey",
      params,
      eddsaKey.sk
    );
    if(DEBUG)
      console.log(eddsaSig)
      
    apiKey = await (await wallets.doApiQuery(accInfo.accountId, eddsaSig)).data.apiKey
    if(DEBUG)
      console.log(apiKey)
    
    const { userNFTBalances } = await LoopringAPI.userAPI.getUserNFTBalances(
      { accountId: accInfo.accountId, limit: 20 },
      apiKey
    );
    if(DEBUG)
      console.log("balances", userNFTBalances)
    return true
  },
  async doAudioTone(audioIndex){
    let audioUrl = '';
    switch(audioIndex){
      case(0):
        audioUrl = audioUrlCube
        break;
      case(1):
        audioUrl = audioUrlTorus;
        break;
      case(2):
        audioUrl = audioUrlTriangle
    }
    
    // Stop any previous tracks, if they're present
    Tone.getTransport().stop(0)
    player.stop(0)
    stopRender = true // initiate the last call to stop filling the data, if applicable
    Tone.setContext(new Tone.Context(new Tone.context.rawContext.constructor({ sampleRate: 192000 })))
    Tone.start();

    // If we're using the WGL renderer, need to ensure its initialized.
    // NB the renderer should only be initialized when the app page is up, otherwise it will
    // error trying to grab a non-existant canvas
    if(!REGL_ENGINE && !draw.isRenderInitialized())
      this.initiateRenderer()

    // the player needs to be constructed each time, otherwise the Transport controls don't seem
    // to work
    player = new Tone.Player();
    player.autostart = false;
    player.toDestination().sync();
    
    // the file is loaded as a blob to make local and remote loading the same processes
    var xhr = new XMLHttpRequest();
    // "https://tonejs.github.io/audio/berklee/arpeggio2.mp3"
    xhr.open("GET", audioUrl, true);
    xhr.responseType = 'blob';
    xhr.onload = async function(){
      var blob = URL.createObjectURL(this.response);

      await player.load(blob);
      
      player.sync().start()
      stopRender = false;
      
      if(REGL_ENGINE){
        function renderCB(){return stopRender}
        draw.renderRGLRaw({preemptCallback: renderCB,maxWaveformLength: waveformSize});
      }

      const toneAnalyzer = new Tone.Analyser({type: "waveform", size: waveformSize, channels: 2, smoothing: 0.1}); // TODO smoothing?
      const lChannel = toneAnalyzer._buffers[0]
      const rChannel = toneAnalyzer._buffers[1]
      player.connect(toneAnalyzer);

      // Break updating if playback has been paused by the user
      // TODO this works OK but looping and sleeping here feels hacky. It's like bad threading
      Tone.getTransport().start()
      draw.updateChannels(lChannel, rChannel)

      function formUpdate(){
        toneAnalyzer._analysers[0].getFloatTimeDomainData(lChannel);
        toneAnalyzer._analysers[1].getFloatTimeDomainData(rChannel);
        if(!stopRender)
          requestAnimationFrame(formUpdate)
      }
      formUpdate()
    };
    xhr.send();

  },
  // this should only be called once, when the App page is launched initially. Only needed when
  // using the WGL rendering engine
  initiateRenderer(){
      draw.renderWGL({maxWaveformLength: waveformSize})
  },
  startRender(){
    stopRender = false;
  },
  stopRender(){
    stopRender = true;
  },
  // a callback handed to the renderer, to determine if rendering should be preempted
  isStopRequested(){
    return stopRender;
  },
  isTonePlaying(){
    const state = player.state;
    if(state == "started")
      return true;
    return false;
  },
  isPlayerLoaded(){
    return player.loaded;
  },
  // Returns true if the player is playing, false otherwise
  toneToggleState(){
    if(!player.loaded)
      return false;

    // check if we a stop event happened prior, if so, resume the track from the beginning
    if(stopPlayer){
      Tone.getTransport().start()
      stopPlayer = false;
      return true
    }
    else if(this.isTonePlaying()){
      Tone.getTransport().pause();
      return false;
    }
    else{
      Tone.getTransport().start()
      return true;
    }
  },
  toneStop(){
    stopPlayer = true;
    Tone.getTransport().stop()
  },
  toneStart(){
    Tone.getTransport().start()
  },
  
  /**
   * Get the error message
   * @param {Object} e - Error response from an API.
   * @returns {string}
   */
  getErrorMsg(e) {
    // e.response.data.msg is for custom rejections
    // e.response.data is for default Warp rejections
    // e.message is the default error message for the error code
    return e.response && (e.response.data.msg || e.response.data) || e.message;
  },

}
