<template>
  <v-main>
  <v-app id="app">
    <v-app-bar app class="pa-0 fill-height" color="primary" dark>
      <v-toolbar-title style="cursor: pointer" @click="toneStop(); $router.push('/Home')">
        <v-img class="mx-2 shadow" src="@/assets/visualmusic.svg" height="40px" width="40px"></v-img>
      </v-toolbar-title>
      <v-toolbar-items>
        <v-btn plain @click="toneStop(); $router.push('/Home')">Home</v-btn>
        <v-btn plain @click="toneStop(); $router.push('/about')">About</v-btn>
        <v-btn plain @click="toneStop(); $router.push('/demo')">Demo</v-btn>
        <v-btn plain @click="toneStop(); $router.push('/nft')">NFT</v-btn>
        <v-btn plain @click="toneStop(); $router.push('/plot')">App</v-btn>
      </v-toolbar-items>
      <v-spacer/>
      <v-btn @click="toneStop(); $router.push('/connect')" :key="connected" v-if="!connected">Connect</v-btn>
      <v-btn @click="toneStop(); $router.push('/plot')" v-else>Launch App</v-btn>
    </v-app-bar>

    <v-container fill-height fluid class="x-ctn">
      <v-row>
        <v-col align="center" cols="12">
          <router-view/>
        </v-col>
      </v-row>
    </v-container>

    <v-footer color="primary lighten-1" padless>
      <v-row justify="center" no-gutters>
        <!-- <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          text
          rounded
          class="my-2">
          {{ link }}
        </v-btn> -->
        <v-col
          class="primary lighten-2 py-4 text-center white--text" cols="12">
          &copy; 2022 — <strong>Maxwell Svetlik</strong> <v-btn plain href="https://maxsvetlik.github.io">Contact</v-btn>  <v-btn plain @click="$router.push('/changelog')">V0.1.2</v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
  </v-main>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.shadow {
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>

import api from '@/api';

export default {
  name: 'App',
  data() {
    return {
      metamask_cond: 0,
      connected: false,
      links: [
      'Home',
      'About',
      'Team',
      'Contact Us',
    ],
    }
  },

  methods: {
    async logout() {
      await api.logout();
    },
    isWeb3LoggedIn(){
      return api.checkWeb3LoggedIn();
    },
    setIsConnected(val){
      this.connected = val
    },
    toneStop(){
      api.toneStop()
    },
  },
  created: function(){
    api.checkWeb3LoggedIn().then((val) => { this.connected = val;} );
    // NOTE: this only works for metamask, not GameStop wallet :(
    // TODO fix for GamestopWallet
    if (window.ethereum){
      window.ethereum.on('accountsChanged', (accounts) => {
        let t = accounts.length > 0 ? true: false;
        this.setIsConnected(t)
      })
    }
  },
  computed: {
    isOnLoginPg() {
      return this.$route.path === '/login';
    },
  },
}

</script>
