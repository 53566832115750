import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colours from 'vuetify/lib/util/colors';


Vue.use(Vuetify);

export default new Vuetify({
  icons:
  {
    iconfont: 'faSvg',
  },
  theme: {
    themes: {
      light: {
        primary: colours.red.lighten1,
        secondary: colours.orange,
        accent: colours.pink.darken2,
        error: colours.red.darken3,
      }
    }
  }
});
