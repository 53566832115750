import { WebglPlot, WebglLine, ColorRGBA } from "webgl-plot";
import createScatterplot from 'regl-scatterplot';

// Normalize within [-1,1]
function normalize(x, max, min){
    return (2*(x - min) / (max - min))-1
}
var left = []
var right = []
var isInitialized = false;

export function updateChannels(l_arr, r_arr){
    left = l_arr;
    right = r_arr;
}

export function isRenderInitialized(){
    return isInitialized;
}
// kicker function for rendering to the canvas using WGL
// TODO : introduce way to trigger a break, in sync with audio controls (callback?)
export async function renderWGL({preemptCallback = function(){false}, normalize=false, maxWaveformLength=2048}){
    const canvas = document.getElementById("canvas");
    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * devicePixelRatio;
    canvas.height = canvas.clientHeight * devicePixelRatio;
    const color = new ColorRGBA(.39,0,.506, 1);
    const line = new WebglLine(color, maxWaveformLength);
    const wglp = new WebglPlot(canvas);
    wglp.addLine(line);

    isInitialized = true;

    // Recursive animation function
    function animateWGL(){
        if(preemptCallback())
            return;
        if(normalize){
            const max = Math.max.apply(Math, right)
            const min = Math.min.apply(Math, right)
        }
        for (let i = 0; i < right.length; i++) {
            line.setY(i, right[i])
            line.setX(i, left[i])
        }
        wglp.update();
        requestAnimationFrame(animateWGL);
    }
    if(!preemptCallback())
        requestAnimationFrame(animateWGL);
    isInitialized = false;
}
// kicker function for using the RGL scatterplot library as the rendering engine.
// In general it performs well, but has non-configurable user events like PAN and ZOOM which make it difficult
// to work with as part of a static canvas
export async function renderRGL({preemptCallback = function(){false}, normalize=false, maxWaveformLength=2048}){
    const canvas = document.getElementById("canvas");
    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * devicePixelRatio;
    canvas.height = canvas.clientHeight * devicePixelRatio;
    const width = canvas.width
    const height = canvas.height
    const xScale = 1;
    const yScale = 1;
    const scatterplot = createScatterplot({
        canvas,
        pointSize: 1.5,
      });
    scatterplot.set({ width, height })
    isInitialized = true;
    const points = new Array(maxWaveformLength)

    // Recursive animation function
    function animateRGL(){
        if(preemptCallback())
            return;
        if(normalize){
            const max = Math.max.apply(Math, right)
            const min = Math.min.apply(Math, right)
        }
        for (let i = 0; i < points.length; i++) {
            points[i] = [right[i], left[i], 1]
        }
        scatterplot.draw(points);
        requestAnimationFrame(animateRGL);
    }
    if(!preemptCallback())
        requestAnimationFrame(animateRGL);
    isInitialized = false;
}

// kicker function for using the RGL wrapper directly, gaining the performance of RGL-scatterplot but retaining more
// control. High performance. Unlike the WGL library, which is intended to be running and never break, this function
// is structured to be called each time a new Player is created. This leads to better memory and CPU performance
// (though its not super clear why)
// Interpolation support exists and is configurable based on `minDist`. See comments below.
export async function renderRGLRaw({preemptCallback = function(){false}, normalize=false, maxWaveformLength=2048}){
    const canvas = document.getElementById("canvas");
    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * devicePixelRatio;
    canvas.height = canvas.clientHeight * devicePixelRatio;
    const width = canvas.width
    const height = canvas.height
    isInitialized = true;
    var pointsPosition = [left, right]
    var interpolatedPoints = [[0,0]]
    // determines the minimum distance for injecting an interpolated point.
    // 0.005 is a pretty good value, but for some sounds, like patterned shapes, this makes artifacts more obvious
    // which isn't ideal. Any value > 0.1 means interpolation is effectively disabled.
    const minDist = 0.25;


    var regl = require('regl')(canvas)

    const drawPoints = regl({
        frag: `
        precision mediump float;
        uniform vec4 color;
        void main () {
          gl_FragColor = color;
        }`,
      
        vert: `
        precision mediump float;
        attribute vec2 position;
        // @change acquire the pointWidth uniform
        //  this is set by the uniforms section below
        uniform float pointWidth;
      
        void main () {
          // @change Set gl_PointSize global to
          //  configure point size
          gl_PointSize = pointWidth;
          gl_Position = vec4(position, 0, 1);
        }`,
      
        attributes: {
          position: function(context, props) {
            return interpolatedPoints
          }
        },
      
        uniforms: {
          color: function(context, props) {
            return props.color;
          },
          pointWidth: regl.prop("pointWidth")
        },
      
        // Note: you can set elements manually, but it seems more useful to bind `count` to a function
        //elements: regl.elements({data: interpolatedPoints, usage: 'dynamic'}),
        count: () => interpolatedPoints.length,
        primitive: "points" // "lines" // "points"
      });

    //Recursive animation function
    const currentColor = [0.39,0,0.506,1]
    const pointWidth = 1.5
    var tick = regl.frame(function(context) {        
            function interpolate(){
                // empty the old buffer
                interpolatedPoints.splice(0)
                // start at one, since we're working off the delta between two points
                for(let i = 1; i < pointsPosition[0].length; i++){
                    let x0 = pointsPosition[0][i-1]
                    let y0 = pointsPosition[1][i-1]
                    let x1 = pointsPosition[0][i]
                    let y1 = pointsPosition[1][i]
        
                    // add the last point
                    interpolatedPoints.push([x0,y0])
                    let xmag = x1-x0 > 0? 1: -1
                    let ymag = y1-y0 > 0? 1: -1
                    let numInterp = Math.round(Math.hypot(x1-x0, y1-y0) / minDist);
                    for(let k = 0; k < numInterp; k++){
                        interpolatedPoints.push([x0+xmag*(minDist*k),y0+ymag*(minDist*k)])
                    }
                }
            }

            pointsPosition = [left, right]
            regl.clear({
                color: [0, 0, 0, 0], // background color (white)
                depth: 1,
            });
            interpolate()
            // Draw the points for this animation frame
            drawPoints({
                pointWidth: pointWidth,
                color: currentColor
              });
        isInitialized = false;
        if(preemptCallback())
            tick.cancel() // escape the regl.frame callback
    });
    
}